import React from "react"
import Emoji from "./emoji"

function StackCard({ name, values, emojis }) {
  const items = values.split(",")
  const emo = emojis.split(",")

  return (
    <div className="stack-card">
      <div className="card-heading">{name}</div>
      <div className="card-body">
        {items.map((s, i) => (
          <p key={i} className="card-info">
            {s} <Emoji symbol={emo[i]} />
          </p>
        ))}
      </div>
    </div>
  )
}

export default StackCard
