import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import StackCard from "../components/StackCard"

import stacks from "../constants/stack"
import SEO from "../components/SEO"

const Uses = () => {
  return (
    <Layout isHome={false}>
      <SEO title="Uses" description="What Sadat Jubayer uses" />
      <div className="wrapper">
        <Title title="My Current Stack" />
        <div className="subheading">What I'm using now?</div>
        <div className="stack-card-container">
          {stacks.map(stack => (
            <StackCard
              key={stack.id}
              name={stack.name}
              values={stack.values}
              emojis={stack.emojis}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Uses

// {
//   id: 1,
//   name: "Operating System",
//   values: "Windows 10, Elementary(Linux distro)",
//   emoji: "🧊",
// },
// {
