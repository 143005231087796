export default [
  {
    id: 4,
    name: "Programming Languages",
    values: "JavaScript, TypeScript, Dart, Python",
    emojis: "💖, 💙, 💚, 💜",
  },

  {
    id: 5,
    name: "Frameworks",
    values: "React ,Redux, Gatsby, NextJS, Flutter",
    emojis: "💖, 😍, 💖, 💙, 💚",
  },

  {
    id: 6,
    name: "Design Tool",
    values: "Figma, Adobe XD",
    emojis: "😍, 🖤",
  },
  {
    id: 7,
    name: "UI Frameworks",
    values: "Material UI, Tailwind CSS, Bootstrap 4",
    emojis: "😃, 😋, 😍",
  },
  {
    id: 8,
    name: "Editor / IDE",
    values: "Visual Studio Code, Theme: Cobalt2, Font: FiraCode",
    emojis: "💖",
  },

  {
    id: 11,
    name: "Productivity",
    values: "Trello, Notion",
    emojis: "😉, 💖",
  },
  {
    id: 0,
    name: "Hardwares",
    values: "Asus UX-410UA, Desktop (Custom PC)",
    emojis: "💻, 🖥",
  },

  {
    id: 1,
    name: "Operating Systems",
    values: "Windows 10, Elementary(Linux distro)",
    emojis: "🧊, 🍔",
  },
  {
    id: 2,
    name: "Terminals",
    values: "Oh My zsh, Hyper,GitBash ",
    emojis: "♐, 🔗, ⚙",
  },
  {
    id: 2,
    name: "IDEs",
    values: "Jetbrains Products",
    emojis: "🤩",
  },
]
